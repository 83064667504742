import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import TopPage from "../components/landingPages/pageContents/TopPage"

const HomePage = ({ data }) => (
  <Layout>
    <SEO title="Trang chủ" />
    <TopPage data={data.topPageJson} />
  </Layout>
)

export default HomePage

export const query = graphql`
  {
    topPageJson {
      banner {
        title
        image {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      aboutus {
        description
        title
        image {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      outstandingCourse {
        description
        title
        slide {
          label
          imgPath {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      Reason {
        title
        content {
          cssCol
          description
          icon
          subtitle
        }
      }
      study {
        subTitle
        title
        img {
          alt
          title
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      Number {
        counter
        icon
        title
      }
      feedBack {
        title
        datamember {
          description
          membername
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      OurTeacher {
        title
        items {
          description
          title
          id
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
      otherApplications {
        description
        title
        contents {
          link
          sub_title
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
