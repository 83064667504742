import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Paper, Typography, Container } from "@material-ui/core";
import Image from "../../../common/Image";

const useStyles = makeStyles((theme) => ({
  aboutUs: {
    backgroundColor: "#E3F3FC",
  },

  root: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    padding: theme.spacing(8, 0, 8, 0),
    height: "800px",
    transition: "all .3s ease-out-in",
    "@media screen and (min-width:768px)": {
      height: "700px",
    },
    "@media screen and (min-width:900px)": {
      height: "800px",
      maxWidth: "1140px",
    },
  },

  content: {
    margin: "0 auto",
    padding: theme.spacing(4, 0, 4, 0),
    width: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: "1",
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translateX(-50%)",
    transition: "all .3s ease-out-in",
    "@media screen and (min-width:768px)": {
      width: "50%",
      height: "75%",
      left: "3%",
      bottom: "-25%",
      transform: "translateY(-50%)",
    },
  },

  description: {
    margin: theme.spacing(3, 3, 0, 3),
    opacity: "0.8",
    fontSize: "16px",
    transition: "all .3s ease-out-in",
    "@media screen and (min-width: 768px)": {
      fontSize: "18px",
      margin: theme.spacing(3, 5, 0, 5),
    },
    "@media screen and (min-width: 1028px)": {
      fontSize: "20px",
      margin: theme.spacing(3, 7, 0, 7),
    },
  },

  Image: {
    margin: "0 auto",
    width: "85%",
    height: "330px",
    objectFit: "cover",
    transition: "all .3s ease-out-in",
    "@media screen and (min-width: 375px)": {
      height: "380px",
    },
    "@media screen and (min-width: 470px)": {
      height: "420px",
    },
    "@media screen and (min-width: 550px)": {
      height: "470px",
    },
    "@media screen and (min-width:768px)": {
      display: "block",
      width: "50%",
      height: "570px",
      marginRight: "3%",
    },
    "@media screen and (min-width:900px)": {
      height: "672px",
    },
  },
}));

function AboutUs({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.aboutUs} id="about-us"
  
     >
      <Container className={classes.root} >
        <Image src={data.image.src} className={classes.Image} />
        <Paper className={classes.content}   >
         
            <Typography variant="h4" align="center" color="primary"  >
              {data.title}
            </Typography>
            <Typography
              variant="body1"
              component="h6"
              align="justify"
              className={classes.description}
              id="about-us-text"
            >
              {data.description}
            </Typography>
        
        </Paper>
      </Container>
    </section>
  );
}

export default AboutUs;
