import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Image from "../../../common/Image";

const useStyles = makeStyles((theme) => ({
  ourTeacher: {
    backgroundColor: "#e3f2fd",
    textAlign: "justify",
    paddingBottom: theme.spacing(6),
  },
  ourTitle: {
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  container_items: {
    padding: theme.spacing(0, 2, 0, 2),
  },
  teacher: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      "&:nth-child(odd)": {
        marginRight: theme.spacing(4),
        "&> div": {
          marginLeft: theme.spacing(4),
        },
        "&> img": {
          marginLeft: theme.spacing(4),
        },
      },
      "&:nth-child(even)": {
        marginLeft: theme.spacing(4),
        flexDirection: "row-reverse",
        "&> div": {
          marginRight: theme.spacing(4),
        },
        "&> img": {
          marginRight: theme.spacing(4),
        },
      },
    },
  },
  image: {
    width: "100%",
    height: "300px",
    objectFit: "cover",
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      height: "350px",
    },
    [theme.breakpoints.up("md")]: {
      width: "350px",
      height: "400px",
    },
  },
  text_title: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  text: {
    opacity: "0.8",
  }
}));

export default function OurTeacher({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.ourTeacher}>
      <Container>
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          align="center"
          className={classes.ourTitle}
        >
          {data.title}
        </Typography>
        <div className={classes.container_items}>
          {data.items.map((item, index) => (
            <div className={classes.teacher} key={index} id ={item.id}>
              <Image className={classes.image} {...item.image} />
              <div className={classes.text}>
                <Typography
                  component="h3"
                  variant="h5"
                  className={classes.text_title}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.text_description}
                >
                  {item.description}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
