import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Image from "../../../common/Image";
import { Card, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  otherApplications: {},
  title: {
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  description: {
    padding: theme.spacing(0, 1, 0, 1),
    opacity: "0.8",
    textAlign: "justify",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      marginBottom: "20px",
    },
  },
  img: {
    margin: "0 auto",
    display: "flex",
    width: "250px",
    height: "250px",
    objectFit: "cover",
    boxShadow:
      "0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.up("md")]: {
      width: "320px",
      height: "320px",
    },
    "&:hover": {
      opacity: "0.8",
      transform: "translateY(-2px)",
      transition: "ease-in-out 0.1s",
    },
  },
  card: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 5% 2%",
    },
  },
  contents: {
    margin: "30px auto",
  },
  cardSubTitle: {
    width: "200px",
    height: "65px",
    border: "3px solid #80d4fa",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    transform: "translate(0, -50%)",
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export default function OtherApplications({ data }) {
  const classes = useStyles();

  return (
    <section className={classes.otherApplications}>
      <Container>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          {data.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {data.description}
        </Typography>
        <div className={classes.card}>
          {data.contents.map((tile) => (
            <div className={classes.contents} id ="app-item">
              <a href={`${tile.link}`} target="_blank" className={classes.link}>
                <Image {...tile.image} className={classes.img} />
                <Card className={classes.cardSubTitle}>
                  <Typography
                    variant="h5"
                    color="primary"
                    align="center"
                    className={classes.subTitle}
                  >
                    {tile.sub_title}
                  </Typography>
                </Card>
              </a>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
