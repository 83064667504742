import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import Background from "../../../../media/landing-pages/top_banner.jpg";

const useStyles = makeStyles((theme) => ({
  banner: {
    background: `url(${Background}) no-repeat`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    width: "100%",
    height: "calc((var(--vh, 1vh) * 100) - 48px)",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      height: "calc(100vh - 64px)",
    },
    "&:before": {
      content: `""`,
      backgroundColor: "#000",
      position: "absolute",
      height: "50%",
      width: "100%",
      bottom: 0,
      right: 0,
      opacity: " 0.45",
      transition: "all .25s ease-in-out",
      [theme.breakpoints.up("md")]: {
        height: "100%",
        width: "50%",
      },
    },
  },

  banner_title: {
    width: "100%",
    textAlign: "center",
    letterSpacing: "3px",
    color: "#fff",
    "& > span": {
      display: "block",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(3),
      },
    },
    position: "absolute",
    zIndex: "1",
    bottom: "25%",
    transform: "translateY(50%)",
    transition: "all .25s ease-in-out",
    [theme.breakpoints.up("md")]: {
      letterSpacing: "5px",
      width: "50%",
      bottom: "50%",
      transform: "translateY(50%)",
    },
  },
}));

//page header viewport height set vh
if (typeof window !== "undefined") {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}

function Banner({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.banner}>
      <Typography
        variant="h2"
        component="h1"
        color="primary"
        dangerouslySetInnerHTML={{ __html: data.title }}
        className={classes.banner_title}
      />
    </section>
  );
}

export default Banner;
