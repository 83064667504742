import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Container, Paper, Typography } from "@material-ui/core";
import Image from "../../../common/Image";

const useStyles = makeStyles((theme) => ({
  study: {
    backgroundColor: "#e3f3fc",
    paddingBottom: theme.spacing(6),
  },
  title: {
    padding: theme.spacing(6, 0, 5, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 8, 5, 8),
    },
  },
  subTitle: {
    fontSize: "16px",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginBottom: "30px",
    opacity: "0.8",
    "@media screen and (min-width: 768px)": {
      fontSize: "18px",
      textAlign: "center",
    },
    "@media screen and (min-width: 1024px)": {
      fontSize: "20px",
      padding: theme.spacing(0, 2, 1, 2),
    },
  },
  imgGroup: {
    padding: theme.spacing(0, 1, 0, 1),
    "@media screen and (min-width: 768px)": {
      width: "100%",
      display: "flex",
    },
  },
  pageImg: {
    width: "100%",
    height: "370px",
    position: "relative",
    borderRadius: 0,
    "@media only screen and (min-width: 350px)": {
      height: "440px",
    },
    "@media only screen and (min-width: 410px)": {
      height: "490px",
    },
    "@media screen and (min-width: 767px)": {
      height: "320px",
      width: "33.33%",
    },
    "@media screen and (min-width: 1024px)": {
      height: "430px",
    },
    "@media screen and (min-width: 1150px)": {
      height: "540px",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textImg: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    color: "#ffffff",
  },
}));

export default function Study({ data }) {
  const classes = useStyles();
  return (
    <section className={classes.study} >
      <Container className={classes.page}>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          {data.title}
        </Typography>
        <Typography align="center" className={classes.subTitle}>
          {data.subTitle}
        </Typography>
        <div className={classes.imgGroup}>
          {data.img.map((item) => {
            return (
              <Paper elevation={0} className={classes.pageImg} id="study-image">
                <Image className={classes.img} src={item.src} alt={item.alt} />
                <Typography
                  variant="h5"
                  component="h5"
                  align="center"
                  className={classes.textImg}
                >
                  {item.title}
                </Typography>
              </Paper>
            );
          })}
        </div>
      </Container>
    </section>
  );
}
