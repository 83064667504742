import React from "react";
import OutstandingCourse from "../common/OutstandingCourse";
import { makeStyles } from "@material-ui/core/styles";
import FeedBack from "../common/Feedback";
import Study from "../sections/top-page/Study";
import { Paper } from "@material-ui/core";
import Reason from "../sections/top-page/Reason";
import Banner from "../sections/top-page/Banner";
import OurTeacher from "../sections/top-page/OurTeacher";
import AboutUs from "../sections/top-page/AboutUs";
import OtherApplications from "../sections/top-page/OtherApplications";
import { useEffect } from "react";
import ScrollReveal from "scrollreveal";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "230px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
  },
}));

// TODO :  check ScrollReveal lại

function ScrollEffect() {
  const sr = ScrollReveal({
    distance: "50px",
    duration: 1400,
    reset: true,
  });

  sr.reveal(
    `#course-item,
            #reason-item,
            #study-image, #app-item`,
    {
      origin: "top",
      interval: 150,
    }
  );

  sr.reveal(`#about-us-text`, {
    origin: "left",
    distance: "30px",
  });

  sr.reveal(`#feedback-member`, {
    origin: "left",
    distance: "30px",
  });

  sr.reveal(`#teacher1`, {
    origin: "left",
    mobile: false,
    distance: "30px",
  });
  sr.reveal(`#teacher2`, {
    origin: "right",
    mobile: false,
    distance: "30px",
  });
  sr.reveal(`#teacher1, #teacher2`, {
    origin: "top",
    desktop: false,
    interval: 100,
  });
}

function ScrollToTop() {
  if (!window) return;
  window.scrollTo(0, 0);
}

export default function Home({ data }) {
  const classes = useStyles();

  useEffect(() => {
    ScrollEffect();
    ScrollToTop();
  },[]);

  return (
    <Paper className={classes.container}>
      <Banner data={data.banner} />
      <OutstandingCourse data={data.outstandingCourse} />
      <AboutUs data={data.aboutus} />
      <Reason data={data.Reason} />
      <Study data={data.study} />
      <FeedBack data={data.feedBack} />
      <OurTeacher data={data.OurTeacher} />
      <OtherApplications data={data.otherApplications} />
    </Paper>
  );
}
