import React from "react";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Image from "../../common/Image";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    feedback: {
      width: "100%",
    },
    root: {
      position: "relative",
      height: "445px",
      marginTop: "3.5rem",
    },
    buttonNext: {
      position: "absolute",
      top: "35%",
      right: "-16px",
    },
    buttonBack: {
      position: "absolute",
      top: "35%",
      left: "-16px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      margin: "10% auto",
      objectFit: "cover",
      height: "100px",
      width: "100px",
      display: "block",
      borderRadius: "50%",
      "@media screen and (min-width: 768px)": {
        margin: "3% auto",
        height: "130px",
        width: "130px",
      },
      "@media screen and (min-width: 1024px)": {
        height: "150px",
        width: "150px",
      },
    },
    dots: {
      transform: "translateX(-50%)",
      left: "50%",
      position: "absolute",
    },
    MobileStepper: {
      backgroundColor: "transparent",
      height: "80px",
      margin: "20px",
    },
    dotActive: {
      backgroundColor: "gray",
    },
    arrow: {
      fontSize: "50px",
    },
    atitle: {
      margin: "0 auto",
      paddingTop: theme.spacing(6),
    },
    Typography: {
      opacity: "0.8",
      margin: "10% 15%",
      textAlign: "justify",
      "@media screen and (min-width: 768px)": {
        margin: "5% 10%",
        textAlign: "center",
      },
      "@media screen and (min-width: 1024px)": {
        margin: "3% 10%",
      },
    },
    Typography1: {
      margin: "10%",
      "@media screen and (min-width: 768px)": {
        margin: "5%",
      },
      "@media screen and (min-width: 1024px)": {
        margin: "3%",
      },
    },
    members: {
      height: "330px",
      "@media screen and (min-width: 1024px)": {
        height: "350px",
      },
    },
  })
);

//TODO: tạo component dùng chung với landing-page
export default function Feedback(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const length = props.data.datamember.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % length);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + length - 1) % length);
  };
  return (
    <section
      className={classes.feedback}
      style={{ backgroundColor: props.color ? props.color : "#fff" }}
      id="feedback"
    >
      <Container>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.atitle}
        >
          {props.data.title}
        </Typography>
        <div className={classes.root}>
          <div className={classes.members} id ="feedback-member">
            <Image
              className={classes.img}
              src={props.data.datamember[activeStep].image.src}
              alt={props.data.datamember[activeStep].image.alt}
            ></Image>
            <Typography
              variant="h5"
              color="primary"
              align="center"
              classes={{ root: classes.Typography1 }}
            >
              {props.data.datamember[activeStep].membername}
            </Typography>
            <Typography
              variant="body1"
              component="h4"
              classes={{ root: classes.Typography }}
            >
              {props.data.datamember[activeStep].description}
            </Typography>
          </div>
          <MobileStepper
            classes={{
              dots: classes.dots,
              root: classes.MobileStepper,
              dotActive: classes.dotActive,
            }}
            position="static"
            steps={length}
            variant="dots"
            activeStep={activeStep}
            nextButton={
              <Button
                className={classes.buttonNext}
                size="small"
                onClick={handleNext}
              // disabled={activeStep === 3}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft className={classes.arrow} />
                ) : (
                    <KeyboardArrowRight className={classes.arrow} />
                  )}
              </Button>
            }
            backButton={
              <Button
                className={classes.buttonBack}
                size="small"
                onClick={handleBack}
              // disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight className={classes.arrow} />
                ) : (
                    <KeyboardArrowLeft className={classes.arrow} />
                  )}
              </Button>
            }
          />
        </div>
      </Container>
    </section>
  );
}
