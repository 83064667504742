import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Container, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5),
    },
  },
  listItem: {
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      paddingBottom: theme.spacing(10),
    },
  },
  item: {
    flexDirection: "column",
    paddingTop: theme.spacing(4),
    transition: "all .3s ease-in-out",
    "&:not(:last-of-type):before": {
      content: `''`,
      width: "70%",
      height: "1px",
      right: "auto",
      bottom: "0",
      position: "absolute",
      backgroundColor: "#FDE313",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      paddingRight: "5%",
      paddingLeft: "5%",
      paddingTop: theme.spacing(8),
      "&:nth-of-type(1)": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(5),
      },
      "&:nth-of-type(2)": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(5),
      },
      "&:nth-of-type(odd):before": {
        width: "1px",
        height: "100%",
        right: "0",
      },
      "&:nth-of-type(2):before": {
        width: "100%",
        height: "1px",
        right: "0",
      },
      "&:nth-of-type(1):after": {
        content: `''`,
        width: "100%",
        height: "1px",
        right: "auto",
        bottom: "0",
        position: "absolute",
        backgroundColor: "#FDE313",
      },
    },
  },
  icon: {
    marginBottom: theme.spacing(3),
    margin: "0 auto",
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(3),
    opacity: "0.8",
  },
}));

export default function Reason({ data }) {
  const classes = useStyles();
  return (
    <section className="reason" >
      <Container>
        <Typography
          variant="h4"
          component="h3"
          color="primary"
          align="center"
          className={classes.title}
        >
          {data.title}
        </Typography>

        <List className={classes.listItem} >
          {data.content.map((item, index) => (
            <ListItem kye={index} className={classes.item} id ="reason-item">
              <Icon
                style={{ fontSize: 80 }}
                color="primary"
                className={classes.icon}
              >
                {item.icon}
              </Icon>
              <Typography
                variant="h5"
                component="h4"
                align="center"
                className={classes.subtitle}
                color="primary"
              >
                {item.subtitle}
              </Typography>
              <Typography
                component="body1"
                align="center"
                className={classes.description}
              >
                {item.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Container>
    </section>
  );
}
